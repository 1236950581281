/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Subtitle, Text, Button } from '@swp/components'
import SiteHeader from '../../components/de/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Fotogalerie"}>
        <SiteHeader />

        <SiteHeader />

        <Column className="--center pb--80 pt--80" name={"einleitung"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--62" content={"<span style=\"color: var(--color-dominant);\">Fotogalerie</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"kontakt-0"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--4" columns={"4"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":800}}>
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/135/img-1_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/135/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/135/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/135/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/135/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/135/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box" content={"Wintertorte"}>
              </Subtitle>

              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/135/img-2_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/135/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/135/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/135/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/135/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/135/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box" content={"Himbeerguss"}>
              </Subtitle>

              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/135/img-3_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/135/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/135/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/135/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/135/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/135/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box" content={"Chokoladenguss"}>
              </Subtitle>

              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/135/img-1_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/135/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/135/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/135/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/135/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/135/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box" content={"Vanilla mit Obst"}>
              </Subtitle>

              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--4 mt--30" columns={"4"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":800}}>
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/135/img-2_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/135/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/135/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/135/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/135/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/135/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box" content={"Zweifarbig für Kinder"}>
              </Subtitle>

              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/135/img-3_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/135/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/135/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/135/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/135/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/135/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box" content={"Leichte Sachertorte"}>
              </Subtitle>

              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/135/img-1_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/135/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/135/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/135/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/135/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/135/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box" content={"Mango-Sorbet"}>
              </Subtitle>

              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/135/img-2_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/135/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/135/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/135/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/135/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/135/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box" content={"Walnusstorte"}>
              </Subtitle>

              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-es5uly pb--60 pt--60" name={"kontakt"} layout={"l1"} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/135/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/135/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/135/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/135/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/135/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/135/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/135/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/135/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper className="pb--10 pt--10">
              
              <Title className="title-box fs--62 lh--1 mt--08" style={{"maxWidth":1276}} content={"<span style=\"color: rgb(255, 255, 255);\">Brauchen Sie einen Kuchen?</span>"}>
              </Title>

              <Button className="btn-box" content={"Kontaktieren Sie uns"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"partei"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--3" anim={"2"} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Süße Leckereien<br>& Backwaren</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Straßenname</span><br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Kontakt</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"+49 797 811 2X05<br>info@vase-stranky.com"}>
              </Text>

              <Text className="text-box" content={"Firmen-Ident.-Nr.: 12345678<br>Erstellt mit <a href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}